@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.footerLogo {
  background: url("/static/logo_yahoo_sports_gray.svg") no-repeat center;
  height: 30px;
  margin: 0 auto $spacing-24;
  width: 158px;
}

.footer {
  @include font-primary-body-B6;
  background-color: $gray95;
  color: $abyss;
  margin-top: $spacing-36;
  padding: $spacing-12 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.articleFooter {
  background-color: unset;
  height: 40px;
  margin: 0 0 $spacing-32 0;
  max-width: calc(100vw - $spacing-24);
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  padding: $spacing-6 0;
  position: relative;
  scrollbar-width: none; /* Firefox */
  width: 100%;

  &::-webkit-scrollbar {
    /* Chrome, Safari, Edge */
    display: none;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: auto;
    margin-bottom: 0;
  }

  .articleLinksContainer {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;

    a {
      @include font-primary-body-B6;
      text-decoration: none;
    }
  }

  .articleLinksContainer::-webkit-scrollbar {
    display: none;
  }

  .footerLinks {
    max-width: unset;
    white-space: nowrap;
    width: max-content;
  }
}

.copyrightSection {
  margin: $spacing-24 0;

  .copyrightNotice {
    margin-bottom: $spacing-8;
  }
}

.footerLinks {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 50px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    max-width: 375px;
    padding: 0 16px;
  }
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    padding: 0 150px;
    width: 768px;
  }

  a {
    @include font-primary-medium-S6;
    color: $abyss;
    display: block;
    line-height: 1.25;
    order: 1;
    padding: $spacing-6 $spacing-12;
    text-decoration: underline;
    white-space: pre-wrap;

    &:not(.unsetLinkColor) {
      color: $abyss;
    }

    &:not(.unsetLinkHoverColor):hover {
      color: $abyss-50;
    }
  }

  img {
    margin-left: 2px;
    max-height: 20px;
    max-width: 25px;
    padding-bottom: 2px;
  }

  .ccpaLinkWrapper {
    @include sizes-ending-with($screen-sm-mobile-max) {
      white-space: normal;
    }
  }
}
