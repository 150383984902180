@import "~rivals/styles/variables";

.overlayContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $header-z-index + 3;
}

.visible {
  display: flex;
}
