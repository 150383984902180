@import "~rivals/components/shared/Form/styles";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.ant-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    color: #000;
    display: flex;
  }
}
