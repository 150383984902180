@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

/* Padding around the alert */
$alert-top-spacing: $spacing-8 $spacing-16;
$alert-min-height: 40px;

/**
  * state-alert refers to info/error/success alerts,
  * they all have the same styles, different icon prefixes though
*/

@mixin state-alert {
  background: $white
    linear-gradient(180deg, rgba($black, 0.06) 0%, rgba($black, 0) 10%);
  color: $abyss;

  .content {
    .textWrapper {
      /* spacing between the alert prefix and message */
      margin-left: $spacing-8;
    }

    .link {
      /* different from siteAdaptive alert type */
      color: $abyss;
    }
  }
}

.alert {
  @include font-primary-body-B5;
  align-items: center;
  display: flex;
  line-height: 1;
  min-height: $alert-min-height;

  .content {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: $alert-top-spacing;

    .link {
      @include font-primary-bold-SB5;
      line-height: 1;
      margin-left: $spacing-4;
      text-decoration: underline;
    }
  }

  .closeButton span {
    display: none;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-primary-body-B6;

    .content {
      justify-content: flex-start;

      .link {
        @include font-primary-bold-SB6;
      }
    }

    .closeButton svg {
      height: 12px;
      width: 12px;
    }
  }
}

.alert.info {
  @include state-alert;
}

.alert.severe {
  @include state-alert;
}

.alert.success {
  @include state-alert;
}

.alert.siteAdaptive {
  // default background color for national sites (rivals, portal, basketballrecruiting)
  // fallback background color in case site primary/secondary colors are not present
  background-color: $clay-50;
  background-image: linear-gradient(
    180deg,
    rgba($black, 0) 0%,
    rgba($black, 0.5) 100%
  );
  color: $bone;

  .content .link {
    color: $bone;
  }

  .closeButton svg g {
    fill: $bone;
  }
}

.alert.withCloseButton .content {
  padding-right: 0;
}

.alert.fullSizeLink {
  align-items: stretch;

  .content {
    padding: 0;

    .textWrapper {
      height: 100%;
      margin: 0;
      width: 100%;
    }

    .link {
      align-items: center;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      margin: 0;
      padding: $alert-top-spacing;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }

  .closeButton {
    align-self: center;
    height: $alert-min-height;
  }
}
