@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/hide-elements";
@import "~rivals/styles/mixins.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

$container-z-index: 1;

.container {
  align-items: center;
  background: linear-gradient(to bottom, $mud-50, $black);
  color: $bone;
  display: flex;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  z-index: $container-z-index;

  &.inBrowser {
    @include fixed-banner-position;
    height: 178px;
    padding: $spacing-12;
    z-index: $ant-carousel-slick-dots-z-index + 1;
    @include sizes-starting-with($screen-sm-desktop-min) {
      column-gap: 9px;
      flex-direction: row;
      height: 114px;
      max-width: 860px;
      padding: $spacing-12 $spacing-24;
    }
  }

  &.inPWA {
    border: 1px solid $bone-10;
    height: 280px;
    padding: 18px 16px $spacing-24 16px;
    width: 344px;
  }
}

.contentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.inBrowser {
    @include sizes-starting-with($screen-sm-desktop-min) {
      column-gap: 9px;
      flex-direction: row;
    }
  }

  &.inPWA {
    height: 100%;
    justify-content: space-between;
  }
}

.accent {
  position: absolute;
  z-index: -$container-z-index;

  &.inBrowser {
    @include sizes-starting-with($screen-sm-desktop-min) {
      left: 0;
      right: unset;
    }
    right: 37%;
  }

  &.inPWA {
    right: 0;
    top: 0;
  }
}

.close {
  @include sizes-ending-with($screen-tablet-max) {
    margin-left: auto;
  }

  &.inPWA {
    position: absolute;
    right: $spacing-12;
    top: $spacing-12;
  }
}

.content {
  column-gap: 9px;
  display: flex;
  flex-direction: row;
  margin-right: auto;

  &.inPWA {
    flex-direction: column;
  }
}

.text {
  margin-right: auto;
  max-width: 345px;
}

.title {
  @include font-primary-bold-SB4;
  @include sizes-starting-with($screen-sm-desktop-min) {
    @include font-primary-bold-SB3;
  }
  margin-top: $spacing-12;

  &.inPWA {
    margin-top: 0;
  }
}

.message {
  @include font-primary-medium-S5;
  line-height: 1.2;
  margin-top: $spacing-8;

  &.inBrowser {
    @include sizes-starting-with($screen-sm-desktop-min) {
      @include font-primary-medium-S4;
      line-height: 1.2;
    }
  }
}

.buttonContainer {
  width: 100%;

  &.inBrowser {
    @include sizes-starting-with($screen-sm-desktop-min) {
      width: 286px;
    }
  }
}

.modalContainer {
  :global {
    .ant-modal .ant-modal-content,
    .ant-modal .ant-modal-header,
    .ant-modal .ant-modal-body {
      padding: 0;
      @include sizes-ending-with($screen-lg-mobile-max) {
        padding: 0;
      }
    }
  }
}
