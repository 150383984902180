@import "~rivals/styles/breakpoints";
@import "~rivals/styles/variables.scss";

.ant-modal {
  .ant-modal-content {
    border-radius: $border-radius-8;
    padding: 0px;
  }

  .ant-modal-header {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    padding: $spacing-24 $spacing-24 $spacing-12 $spacing-24;
    @include sizes-ending-with($screen-lg-mobile-max) {
      padding: $spacing-24 $spacing-12 $spacing-12;
    }
  }

  .ant-modal-body {
    padding: $spacing-12 64px $spacing-12 $spacing-24;
    @include sizes-ending-with($screen-lg-mobile-max) {
      padding: $spacing-12;
    }
  }

  .ant-modal-footer {
    border-radius: 0 0 8px 8px;
    border-top: none;
    padding: $spacing-24;
    @include sizes-ending-with($screen-lg-mobile-max) {
      padding: $spacing-12 $spacing-12 $spacing-24;
    }

    &:first-child {
      margin-right: $spacing-12;
    }
  }
}
