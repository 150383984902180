/*
  (!) DEPRECATED (!)

  These global styles are being deprecated in favor of design tokens, component tokens, and component level scss files.
  See AntD documentation concerning design and component tokens: https://ant.design/docs/react/customize-theme

  Currently, the page router scoped components are using these styles in conjunction with the "legacy" theme.
  The app router scoped components do not use these styles.
*/

@import "~antd/dist/reset.css";
@import "~rivals/styles/antd-auto-complete-overrides";
@import "~rivals/styles/antd-collapse-overrides";
@import "~rivals/styles/antd-form-item-overrides";
@import "~rivals/styles/antd-modal-overrides";
@import "~rivals/styles/antd-select-overrides";
@import "~rivals/styles/antd-tooltip-overrides";
@import "~rivals/styles/antd-menu-overrides";

// Mixins

@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";

// Helpers

@import "~rivals/styles/helpers";

// OBI billing page overrides

.obick-card-number::after {
  content: "";
  display: none;
  height: 33px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
  @include sizes-starting-with($screen-tablet-min) {
    right: 29px;
  }
  @include sizes-ending-with($screen-tablet-min) {
    right: 5px;
  }
}

.obick-payment-type-MASTER_CARD::after {
  background: url("/static/credit_cards/mastercard.png");
  background-size: contain;
  display: block;
}

.obick-payment-type-VISA::after {
  background: url("/static/credit_cards/visa.png");
  background-size: contain;
  display: block;
}

.obick-payment-type-AMERICAN_EXPRESS::after {
  background: url("/static/credit_cards/amex.png");
  background-size: contain;
  display: block;
}

.obick-payment-type-DISCOVER::after {
  background: url("/static/credit_cards/discover.png");
  background-size: contain;
  display: block;
}

body.rivals-body > div#__next {
  background-color: $gray95;
}

ul.ant-menu-horizontal {
  @include nav-link-font;
  border-bottom: 0px;
  height: 36px;
  width: 100%;
}

li.ant-menu-item {
  @include nav-link-font;
  height: 36px;
  padding-left: 0px;
  padding-right: 0px;
}

.ant-menu-item .ant-menu-title-content > a {
  @include nav-menu-alignment;
  height: 32px;
  @include sizes-starting-with($screen-sm-desktop-min) {
    padding: 0 $spacing-12;
  }

  &::before {
    @include nav-menu-alignment;
    height: 36px;
  }
}

.ant-menu-horizontal > .ant-menu-item > a {
  @include nav-menu-alignment;
  @include nav-link-font;
  height: 36px;
  @media screen and (min-width: $screen-tablet-min) {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: $screen-tablet-min) {
    &:hover {
      @include inline-menu-hover;
    }
  }
}

.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected > a {
  color: $rivals-blue;
}

ul.ant-menu-submenu {
  @include nav-link;
  background-color: $white;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  @include nav-menu-alignment;
  @include nav-link;
  text-align: left;
  @media screen and (max-width: $screen-tablet-min) {
    &:hover {
      @include inline-menu-hover;
    }
  }

  a {
    color: $black;

    &:hover {
      color: $gray50;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 5px 0;

  ::after {
    border-bottom: none;
  }

  &:hover::after {
    border-bottom: none;
  }
}

.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-submenu {
  margin: $spacing-2 $spacing-4;
  overflow: hidden;
  width: auto;

  .ant-menu-submenu-title {
    border-radius: unset;
    height: fit-content;
    margin: 0;
    padding: 0;
    width: unset;

    .ant-menu-title-content {
      position: relative;
      z-index: 2;

      a,
      span {
        @include font-primary-body-B5;
        @include nav-menu-alignment;
        color: $black;
        height: 32px;
        overflow: hidden;
        padding: 0 10px;
      }
    }

    .ant-menu-submenu-arrow {
      z-index: 1;
    }
  }
}

.ant-menu-submenu .ant-menu.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  @include nav-submenu-item-padding;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  width: auto;

  &:hover {
    background-color: unset;
  }
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  @include nav-link;

  .ant-menu-title-content {
    a {
      @include nav-link;

      &:hover {
        color: $link-hover;
      }
    }
  }
}

.ant-menu-inline .ant-menu-item {
  @include nav-link;
}

.ant-progress-inner {
  border: 1px solid $white;
}

.ant-select-dropdown-menu-item {
  @include font-yahoo-b4;
}

.ant-tooltip-arrow {
  &::before {
    background-color: $white;
  }
}

.ant-tooltip-inner {
  @include font-yahoo-b5;
  background-color: $white;
  color: $black;
}
