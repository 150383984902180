@import "~rivals/styles/colors";

.ant-form-item-explain.ant-form-item-explain-error {
  color: $fail;
}

.ant-form-item.ant-form-item-has-error .ant-form-item-control-input-content > *,
.ant-form-item.ant-form-item-has-error
  .ant-form-item-control-input-content
  > *:hover {
  border-color: $gray85;
}

.ant-form-item.ant-form-item-has-error .ant-picker-focused {
  box-shadow: 0 0 0 2px rgb($dodger-blue, 0.2);
}
