@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.ant-collapse {
  background-color: transparent;
}

.ant-collapse-header {
  @include font-yahoo-h4;
  @include sizes-starting-with($screen-tablet-min) {
    @include font-yahoo-h3-secondary;
  }

  svg {
    margin-top: $spacing-12;
    position: absolute;
    right: 16px;
  }
}

.ant-collapse-item-active > .ant-collapse-header {
  @include font-yahoo-h3-secondary;
  @include sizes-starting-with($screen-tablet-min) {
    @include font-yahoo-h25;
  }
}

.ant-collapse-borderless > .ant-collapse-item,
.ant-collapse-item:last-child {
  background-color: $gray95;
  border: 0;
  margin-bottom: $spacing-24;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-item {
  border-radius: $border-radius-8;
}
