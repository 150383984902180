@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

$logo-height: 25px;
$logo-width: 79px;

.headerText {
  @include font-extended-extrabold-A3;
  color: $white;
  margin-left: $spacing-24;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  @include sizes-ending-with($screen-tablet-max) {
    @include font-extended-extrabold-A5;
    margin-left: 0;
  }
}

.rivalsLogo {
  height: $logo-height;
  @include sizes-starting-with($screen-sm-desktop-min) {
    margin-left: $spacing-24;
  }
}

.rivalsLogoWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  @include sizes-starting-with($screen-sm-desktop-min) {
    flex: 1;
    width: calc(100% - 80px);
  }
}

.rLogo {
  @media screen and (max-width: $screen-tablet-max) {
    display: none;
  }
  display: block;
  height: $header-height-desktop;
  padding: 18px 18px;
  position: relative;
  width: $logo-width;

  &::after {
    border-right: 2px solid $bone;
    bottom: 0;
    content: "";
    height: 18px;
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
  }
}

.responsiveRivalsLogo {
  height: 24px;
  width: 159px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: 16px;
    width: 108px;
  }
}
