/* stylelint-disable declaration-no-important */
@import "~rivals/components/shared/Form/styles";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

@mixin selectHover {
  background-color: $sand-30 !important;
}

.ant-select.ant-select-lg {
  display: flex;
}

.ant-select.ant-select-lg .ant-select-selector {
  @include input;
}

.ant-select-borderless.ant-select.ant-select-lg .ant-select-selector {
  border-color: transparent;
}

.ant-select-selector .ant-select-selection-item,
.ant-select-selection-placeholder {
  @include font-yahoo-b4;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-select-dropdown,
:where(.css-1kuana8).ant-select-dropdown {
  border-radius: 0 !important;
  padding: $spacing-16 0 !important;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    @include selectHover;
  }

  .ant-select-item-option {
    border-radius: 0 !important;
  }

  .ant-select-item-group {
    @include font-primary-medium-S5;
  }

  .ant-select-item-option:hover {
    @include selectHover;
  }
}

.ant-select-item-option:hover {
  @include selectHover;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  // match the hover or sorted column head background with the selected sort column background
  background: $grayLightShade;
}

@mixin antd-select-dark-mode-override(
  $bg-color,
  $border,
  $border-radius,
  $text-color
) {
  // These select overrides are needed for both dev and prod builds. Created ticket to investigate potential removal: https://griosf.atlassian.net/browse/RVLS-9110

  :where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-customize-input)
    .ant-select-selector,
  :where(.css-1kuana8).ant-select-outlined:not(.ant-select-customize-input)
    .ant-select-selector {
    background: $bg-color;
    border: $border;
    border-radius: $border-radius;
    color: $text-color;
  }

  :where(.css-dev-only-do-not-override-1kuana8).ant-select
    .ant-select-selection-placeholder,
  :where(.css-1kuana8).ant-select .ant-select-selection-placeholder {
    @include font-extended-headline-H5;
    color: $text-color;
  }

  :where(.css-dev-only-do-not-override-1kuana8).ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input,
  :where(.css-1kuana8).ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
    width: 95%;
  }

  :where(.css-dev-only-do-not-override-1kuana8).ant-select-single.ant-select-lg.ant-select-open
    .ant-select-selection-item,
  :where(.css-1kuana8).ant-select-single.ant-select-lg.ant-select-open
    .ant-select-selection-item {
    color: $text-color;
  }

  .ant-select-selection-search-input {
    @include font-extended-headline-H5;
    color: $text-color;
  }
}
