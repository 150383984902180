@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/mixins.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.title {
  @include font-yahoo-h2;

  &.hasCloseIcon {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .closeIconWrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-left: $spacing-24;
      min-height: 32px;
      min-width: 32px;

      .closeIcon {
        background: url("/static/icons/close_modal_icon.svg") no-repeat center;
        background-size: $spacing-12 $spacing-12;
        height: $spacing-12;
        width: $spacing-12;
      }
    }
  }
}

.body {
  @include font-yahoo-b3;
  color: $black;
}

.footer {
  @include button-container;
  @include sizes-starting-with($screen-tablet-min) {
    &.rightAlign {
      justify-content: flex-end;
    }
  }

  .leftButton {
    @include button-secondary;
  }
}

:export {
  antdModalWidth: $ANTD_MODAL_DEFAULT_WIDTH;
}
