@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

@mixin input-label {
  @include font-yahoo-h5;
  color: $black;
  line-height: 16px;
  width: 100%;
}

@mixin input {
  @include font-yahoo-b4;
  @include border-with-color(1px, $gray85);
  border-radius: $border-radius-4;
  color: $gray30;
  height: $input-height;
  outline: 0;
  padding: 0 12px;
  width: 100%;
}
