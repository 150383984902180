@import "~rivals/styles/colors.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";
@import "~rivals/styles/exports.module.scss";

a.buttonLink {
  position: relative;
}

// Rivals Button Styles

.button {
  align-items: center;
  background-color: $star;
  border: none;
  color: $abyss;
  display: flex;
  justify-content: center;

  &:disabled {
    background-color: $sand;
    color: $bone-50;
    cursor: not-allowed;
  }

  &:hover:enabled,
  &:active:enabled {
    background-color: rgba($star, 0.3);
    cursor: pointer;
  }

  &:active:enabled {
    color: $abyss-70;
  }
}

// Secondary Light

.button.inverted {
  background-color: $bone;
  border: solid 1px $abyss;
  color: $abyss;

  &:disabled {
    border-color: $abyss-10;
    color: $abyss-10;
    cursor: not-allowed;
  }

  &:hover:enabled,
  &:active:enabled {
    background-color: $bone;
    border-color: $abyss-70;
    color: $abyss-70;
    cursor: pointer;
  }

  &:active:enabled {
    color: $abyss-70;
  }
}

.dark {
  background-color: $mud;
  border: none;
  color: $bone;

  &:disabled {
    background-color: $mud-50;
    color: $bone;
    cursor: not-allowed;
  }

  &:hover:enabled,
  &:active:enabled {
    background-color: $mud-70;
    cursor: pointer;
  }

  &:active:enabled {
    color: $bone-30;
  }
}

.medium,
.mediumLegacy {
  @include font-extended-headline-H5;
  height: 48px;
  min-width: 186px;
  padding: 0 $spacing-24;
  padding-bottom: 1px;
}

.small,
.smallLegacy {
  @include font-extended-headline-H5;
  height: 32px;
  padding: 0 $spacing-16;
  padding-bottom: 1px;
}

.extraSmall {
  @include font-primary-bold-SB7;
  height: 19px;
  width: 103px;
}

.smallLegacy {
  @include font-yahoo-h5;
}

.fullWidth,
.fullWidthLegacy {
  width: 100%;
}

.text {
  &.leadingIcon {
    padding-left: $spacing-4;
  }

  &.trailingIcon {
    padding-right: $spacing-4;
  }
}

// Legacy Button Styles

.legacyButton {
  @include font-yahoo-h4;
  background-color: $call-to-action-blue;
  border: solid 1px transparent;
  border-radius: 30px;
  color: $white;
  display: block;
  line-height: 20px;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover:enabled {
    cursor: pointer;
  }

  &.inverted {
    background-color: transparent;
    border-color: $call-to-action-blue;
    color: $call-to-action-blue;
  }
}
