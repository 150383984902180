@import "~rivals/styles/exports.module.scss";

@mixin set-icon-dimensions($height, $width) {
  height: $height;
  min-height: $height;
  min-width: $width;
  width: $width;
}

.warning {
  @include set-icon-dimensions(17px, 20px);
}

.check {
  @include set-icon-dimensions(18px, 18px);
}
