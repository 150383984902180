@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/mixins.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.container {
  background: linear-gradient(to bottom, $mud-50, $black);
  display: flex;
  flex-direction: column;
  height: 164px;
  justify-content: space-between;
  padding: $spacing-12;
  position: relative;
  z-index: 1;
  @include sizes-starting-with($screen-tablet-min) {
    height: 136px;
  }

  &.fixed {
    @include fixed-banner-position;
    z-index: $ant-carousel-slick-dots-z-index + 1;
  }
}

.title {
  @include font-primary-bold-SB4;
}

.content {
  @include font-primary-medium-S5;
  align-items: flex-start;
  color: $bone-10;
  column-gap: $spacing-4;
  display: flex;
  line-height: 1.2;
  margin-bottom: $spacing-12;
}

.close {
  margin-left: auto;
}

.splatter {
  bottom: 0;
  height: 108px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
